<template>
  <div class="csr_management" style="padding: 5px 10px 10px 10px">
    <AddCsr :getCsr="getCsr" />
    <div v-if="!isLoading">
      <div class="testingScroll">
        <table>
          <tr>
            <th v-for="item in table_header" :key="item.id">{{ item }}</th>
          </tr>
          <tr v-for="(csr, index) in allCsr" :key="index">
            <td class="show_on_front">
              <i v-if="csr.show == true" class="fal fa-check-circle"></i>
              <i v-if="csr.show == false" class="fal fa-ban"></i>
            </td>
            <td>
              <div
                v-if="lang == 'ka'"
                class="short_text"
                v-html="csr.titleKA"
              ></div>
              <div v-else class="short_text" v-html="csr.titleEN"></div>
            </td>
            <td>
              <div
                v-if="lang == 'ka'"
                :ref="'descriptionKA' + index"
                class="short_text"
                style="padding-left: 20px"
              ></div>
              <div
                v-else
                :ref="'descriptionEN' + index"
                class="short_text"
                style="padding-left: 20px"
              ></div>
            </td>
            <td>
              <hooper
                v-if="csr.images != 'null'"
                :progress="true"
                :autoPlay="true"
                :playSpeed="3000"
                :hover="true"
                style="border-radius: 0px"
              >
                <slide v-for="item in csr.images" :key="item._id">
                  <div class="conatiner" style="height: 100px; width: 150px">
                    <img
                      style="height: 100px; width: 150px"
                      :src="`${server_url}/${item.image}`"
                      :alt="item"
                    />
                  </div>
                </slide>
                <hooper-navigation slot="hooper-addons"></hooper-navigation>
                <hooper-pagination slot="hooper-addons"></hooper-pagination>
              </hooper>
            </td>
            <td>
              <iframe
                v-if="csr.video !== 'null'"
                width="200"
                height="100"
                :src="csr.video"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </td>
            <td>
              <div v-for="item in csr.keywords" :key="item.id">
                {{ item }}
              </div>
            </td>
            <td>
              <button
                class="edit"
                @click="
                  (modalShow = !modalShow),
                    editCsr(
                      csr._id,
                      csr.show,
                      csr.titleKA,
                      csr.titleEN,
                      csr.descriptionKA,
                      csr.descriptionEN,
                      csr.video,
                      csr.keywords,
                      csr.images
                    )
                "
              >
                <i class="fal fa-pen"></i>
              </button>
            </td>
            <td>
              <ConfirmDelete @clicktodelete="deleteCsr(csr._id)" />
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="5"
        :columns="8"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>
    <b-modal v-model="modalShow" id="modal-xl-2" size="xl" hide-footer>
      <div class="text_editor">
        <label for="">{{ $t("admin.title") }} (KA)</label>
        <b-form-input v-model="update.titleKA"></b-form-input>
        <label for="">{{ $t("admin.title") }} (EN)</label>
        <b-form-input v-model="update.titleEN"></b-form-input>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("admin.description") }} (KA)</label>
        <vue-editor v-model="update.descriptionKA"></vue-editor>
        <label for="">{{ $t("admin.description") }} (EN)</label>
        <vue-editor v-model="update.descriptionEN"></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("admin_sidebar.video") }}</label>
        <input class="input" type="text" v-model="update.video" />
      </div>
      <div class="text_editor" v-if="update.keywords.length !== 0">
        <label for="">{{ $t("admin.choosed_keywords") }}</label>
        <div
          v-for="(item, index) in update.keywords"
          :key="index"
          class="d-flex"
        >
          <div class="choosed_keywords">
            {{ item }}
          </div>
          <i
            class="fal fa-times-square"
            style="cursor: pointer; margin-left: 20px; font-size: 20px"
            @click="delete_keywords(index)"
          ></i>
        </div>
      </div>
      <div class="text_editor add_comp">
        <label for="">{{ $t("admin.keywords") }}</label>
        <i class="fal fa-plus-square" @click="add_component()"></i>
        <div v-for="(comp, i) in components" :key="comp.id" class="d-flex">
          <input
            class="input add_component"
            type="text"
            v-model="comp[i]"
            @change="get_value($event)"
          />
          <i class="fal fa-times-square" @click="delete_comp"></i>
        </div>
      </div>
      <div>
        <label for="">{{ $t("admin.activate_on_front") }}</label>
      </div>
      <input class="checkbox" type="checkbox" v-model="update.show" />
      <hooper class="hooper_edit" :itemsToShow="3" :itemsToSlide="3">
        <slide style="width: 160px" v-for="item in images" :key="item._id">
          <div class="upload_image">
            <img
              class="image"
              style="height: 100px; width: 150px"
              :src="`${server_url}/${item.image}`"
            />
            <div class="overlay">
              <div class="icon d-flex">
                <input
                  type="file"
                  accept="image/jpg, image/png, image/jpeg"
                  multiple
                  id="file"
                  class="hide_choose_file"
                  @change="editImage($event, item._id)"
                />
                <i
                  class="fal fa-trash"
                  @click="
                    deleteImage(
                      item._id,
                      update.show,
                      update.titleKA,
                      update.titleEN,
                      update.descriptionKA,
                      update.descriptionEN,
                      update.video,
                      update.keywords
                    )
                  "
                ></i>
              </div>
            </div>
          </div>
        </slide>
        <hooper-navigation slot="hooper-addons"></hooper-navigation>
        <hooper-pagination slot="hooper-addons"></hooper-pagination>
      </hooper>
      <input
        type="file"
        style="margin: 10px 0"
        accept="image/jpg, image/png, image/jpeg"
        multiple
        class="input"
        @change="previewFiles($event)"
      />
      <button type="submit" class="submit btn btn-primary" @click="UpdateCsr()">
        <span v-show="!isLoading">{{ $t("admin.edit") }}</span>
        <span v-show="isLoading">
          {{ $t("login.loading") }}
          <spinner v-show="isLoading" text="loading" />
        </span>
      </button>
    </b-modal>
  </div>
</template>

<script>
import ConfirmDelete from "../../../components/Confirm/DeleteConfirm.vue";
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
} from "hooper";
import "hooper/dist/hooper.css";
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import Swal from "sweetalert2";
import AddCsr from "../AddComponents/AddCsr.vue";
import spinner from "../../spinner/Spinner.vue";
export default {
  name: "getTeam",
  components: {
    spinner,
    AddCsr,
    VueEditor,
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    ConfirmDelete,
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      table_header: [
        this.$t("admin.active"),
        this.$t("admin.title"),
        this.$t("admin.description"),
        this.$t("admin.image"),
        this.$t("admin_sidebar.video"),
        this.$t("admin.keywords"),
        this.$t("admin.edit"),
        this.$t("admin.delete"),
      ],
      checkedItems: [],
      allCsr: [],
      active_id: "",
      image_id: "",
      update: {
        keywords: [],
        show: false,
        titleEN: "",
        titleKA: "",
        descriptionKA: "",
        descriptionEN: "",
        video: null,
      },
      images: null,
      edit_image: null,
      components: [{ comp: "comp" }],
      server_url: env.server_url,
      isLoading: false,
      lang: "ka",
    };
  },
  mounted() {
    this.getCsr();
    if (localStorage.getItem("lang") == "ka") {
      this.lang = "ka";
    } else {
      this.lang = "en";
    }
  },
  methods: {
    getCsr() {
      this.isLoading = true;
      axios
        .get(`${env.host}/get/all/csr/${null}/${null}/${null}`)
        .then((res) => {
          this.isLoading = false;
          res.data.item.map((csr, index) => {
            this.$nextTick(() => {
              this.$refs["descriptionKA" + index].forEach((element) => {
                element.insertAdjacentHTML("beforeend", csr.descriptionKA);
              });
            });
            this.$nextTick(() => {
              this.$refs["descriptionEN" + index].forEach((element) => {
                element.insertAdjacentHTML("beforeend", csr.descriptionEN);
              });
            });
            this.active_id = csr._id;
          });
          this.allCsr = res.data.item;
        });
    },
    add_component() {
      this.components.push({ comp: "comp" });
    },
    delete_comp() {
      this.components.pop();
    },
    delete_keywords(index) {
      if (index > -1) {
        this.update.keywords.splice(index, 1);
      }
    },
    get_value(e) {
      this.form.keywords.push(e.target.value);
    },
    previewFiles(e) {
      const image = e.target.files;
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.images = image;
    },
    editImage(e, id) {
      const image = e.target.files;
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.image_id = id;
      this.edit_image = image;
    },
    editCsr(
      id,
      show,
      titleKA,
      titleEN,
      descriptionKA,
      descriptionEN,
      video,
      keywords,
      images
    ) {
      this.active_id = id;
      this.update.show = show;
      this.update.titleKA = titleKA;
      this.update.titleEN = titleEN;
      this.update.descriptionKA = descriptionKA;
      this.update.descriptionEN = descriptionEN;
      this.update.video = video;
      this.update.keywords = keywords;
      this.images = images;
    },
    UpdateCsr() {
      this.isLoading = true;
      if (this.update.video == null && this.images == null) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.enter_parameters"),
        });
        this.isLoading = false;
      }
      axios
        .post(`${env.host}/edit/csr/${this.active_id}`, this.update)
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_edit"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(() => {
            this.getCsr();
          }, 1000);
        });

      if (!Array.isArray(this.images)) {
        for (let i = 0; i < this.images?.length; i++) {
          const formData = new FormData();
          formData.append("image", this.images[i]);
          axios
            .post(`${env.host}/add/image/csr/${this.active_id}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => {
              Swal.fire({
                icon: "success",
                text: this.$t("success.success_edit"),
              });
              this.isLoading = false;
              this.modalShow = false;
              setTimeout(() => {
                this.getCsr();
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
      if (this.edit_image !== null) {
        this.images = this.edit_image;
        for (let i = 0; i < this.images?.length; i++) {
          const formData = new FormData();
          formData.append("image", this.images[i]);
          axios
            .post(
              `${env.host}/edit/image/csr/${this.active_id}/${this.image_id}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {
              Swal.fire({
                icon: "success",
                text: this.$t("success.success_edit"),
              });
              this.isLoading = false;
              this.modalShow = false;
              setTimeout(() => {
                this.getCsr();
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    deleteImage(
      id,
      show,
      titleKA,
      titleEN,
      descriptionKA,
      descriptionEN,
      video,
      keywords
    ) {
      this.isLoading = true;
      axios
        .delete(`${env.host}/delete/image/csr/${this.active_id}/${id}`)
        .then(() => {
          axios
            .get(`${env.host}/get/all/csr/${null}/${null}/${null}`)
            .then((res) => {
              res.data.item.map((item) => {
                this.editCsr(
                  this.active_id,
                  show,
                  titleKA,
                  titleEN,
                  descriptionKA,
                  descriptionEN,
                  video,
                  keywords,
                  item.images
                );
                this.isLoading = false;
              });
            });
        });
    },
    deleteCsr(id) {
      this.isLoading = true;
      axios.delete(`${env.host}/delete/csr/${id}`).then(() => {
        Swal.fire({
          icon: "success",
          text: this.$t("success.success_delete"),
        });
        this.isLoading = false;
        this.getCsr();
      });
    },
  },
};
</script>

<style scoped>
.csr_management {
  width: 100%;
}
.testingScroll {
  width: 100% !important;
  min-height: calc(100vh - 240px);

  max-height: calc(100vh - 150px) !important;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e549374;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e168274;
  cursor: pointer;
}

label {
  font-family: mtavruliBOLD !important;
}
.hooper_edit {
  background: #f2f5fa;
  padding: 10px;
  height: 150px !important;
}
input#file {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  padding: 40px 0 0 0;
  height: 10px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url("https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png")
    center center no-repeat #e4e4e4;
  border-radius: 5px;
  background-size: 30px 30px;
}
.upload_image {
  position: relative;
}
.image {
  display: block;
  width: 150px;
  height: 100px;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  width: 150px;
  opacity: 0;
  transition: 0.3s ease;
  background-color: #7e5493;
}
.upload_image:hover .overlay {
  opacity: 1;
}
.icon {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.icon i {
  cursor: pointer;
  margin-left: 5px;
  padding: 10px;
  background: #e4e4e4;
  border-radius: 5px;
  color: #157efb;
  transition: 0.2s;
}
.icon i:hover {
  transition: 0.2s;
  color: #157efb;
}
.show_on_front {
  font-size: 30px;
}
.hooper {
  height: 100px;
}
.short_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
input[type="checkbox"] {
  -ms-transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(2);
  padding: 10px;
}
label {
  margin-top: 20px;
}
.checkbox {
  margin-bottom: 20px;
  margin-left: 10px;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.edit i {
  color: white;
}
.add_comp i {
  cursor: pointer;
  margin-left: 20px;
  font-size: 20px;
}
table {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  border-radius: 0px;
  overflow: hidden;
}
thead {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
th {
  background: #7e5493;
  color: #fff;
}
td:nth-child(1) {
  background: #7e5493;
  color: #fff;
  border-radius: 0px;
}

th,
td {
  padding: 10px;
}
tr,
td {
  display: block;
}
td {
  position: relative;
}
td::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  padding-left: 1em;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
}

tr {
  margin-bottom: 1.5em;
  border: 1px solid #ddd;
  border-radius: 0px;
  text-align: right;
}
tr:last-of-type {
  margin-bottom: 0;
}
td:nth-child(n + 2):nth-child(odd) {
  background-color: #ddd;
}
@media only screen and (min-width: 768px) {
  thead {
    visibility: visible;
    position: relative;
  }
  th {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  tr {
    display: table-row;
    border: none;
    border-radius: 0px;
    text-align: left;
  }
  tr:nth-child(even) {
    background-color: #ddd;
  }
  td {
    display: table-cell;
  }
  td::before {
    content: none;
  }
  td:nth-child(1) {
    background: transparent;
    color: #444;
    border-radius: 0px;
  }
  td:nth-child(n + 2):nth-child(odd) {
    background-color: transparent;
  }
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
.input,
.choosed_keywords {
  border-radius: 0px;
  width: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  height: 40px;
  padding-left: 10px;
}
</style>
