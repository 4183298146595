<template>
  <div class="team">
    <b-button @click="modalShow = !modalShow">{{
      $t("add_csr.add_csr")
    }}</b-button>
    <b-modal id="modal-xl" v-model="modalShow" size="xl" hide-footer>
      <form @submit.prevent="AddCsr()">
        <div class="text_editor">
          <label for="">{{ $t("admin.title") }} (KA)</label>
          <b-form-input v-model="form.titleKA"></b-form-input>
          <label for="">{{ $t("admin.title") }} (EN)</label>
          <b-form-input v-model="form.titleEN"></b-form-input>
        </div>
        <div class="text_editor">
          <label for="">{{ $t("admin.description") }} (KA)</label>
          <vue-editor v-model="form.descriptionKA"></vue-editor>
          <label for="">{{ $t("admin.description") }} (EN)</label>
          <vue-editor v-model="form.descriptionEN"></vue-editor>
        </div>
        <div class="text_editor">
          <label for="">{{ $t("admin_sidebar.video") }}</label>
          <input class="input" type="text" v-model="form.video" />
        </div>
        <div class="text_editor">
          <label for=""> {{ $t("admin.keywords") }}</label>
          <i class="fal fa-plus-square" @click="add_component()"></i>
          <div v-for="(comp, i) in components" :key="comp.id" class="d-flex">
            <input
              class="input add_component"
              type="text"
              v-model="comp[i]"
              @change="get_value($event)"
            />
            <i class="fal fa-times-square" @click="delete_comp"></i>
          </div>
        </div>
        <div>
          <label for="">{{ $t("admin.activate_on_front") }}</label>
        </div>
        <input class="checkbox" type="checkbox" v-model="form.show" />
        <input
          type="file"
          style="margin: 10px 0"
          accept="image/jpg, image/png, image/jpeg"
          class="input"
          multiple
          @change="previewFiles($event)"
        />
        <button type="submit" class="submit btn btn-primary">
          <span v-show="!isLoading">{{ $t("admin.add") }}</span>
          <span v-show="isLoading">
            {{ $t("login.loading") }}
            <spinner v-show="isLoading" text="loading" />
          </span>
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";

export default {
  components: {
    VueEditor,
    spinner,
  },
  props: {
    getCsr: { type: Function },
  },
  name: "login",
  data() {
    return {
      clearName: "",
      form: {
        keywords: [],
        show: false,
        titleEN: "",
        titleKA: "",
        descriptionKA: "",
        descriptionEN: "",
        video: null,
      },
      images: null,
      components: [{ comp: "comp" }],
      isLoading: false,
      modalShow: false,
    };
  },
  methods: {
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
    previewFiles(e) {
      const image = e.target.files;
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.images = image;
    },
    add_component() {
      this.components.push({ comp: "comp" });
    },
    delete_comp() {
      this.components.pop();
    },
    get_value(e) {
      this.form.keywords.push(e.target.value);
    },
    AddCsr() {
      this.isLoading = true;
      if (this.form.video == null && this.images == null) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.enter_parameters"),
        });
        this.isLoading = false;
      }
      axios
        .post(`${env.host}/add/csr`, this.form)
        .then((res) => {
          if (res.data._id) {
            for (let i = 0; i < this.images.length; i++) {
              const formData = new FormData();
              formData.append("image", this.images[i]);
              axios
                .post(`${env.host}/add/image/csr/${res.data._id}`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(() => {
                  Swal.fire({
                    icon: "success",
                    text: this.$t("success.success_add"),
                  });
                  this.isLoading = false;
                  this.modalShow = false;
                  this.emptyValues();
                  setTimeout(() => {
                    this.getCsr();
                  }, 1000);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.error = err;
          console.log(this.error);
        });
    },
    emptyValues() {
      this.components = [{ comp: "comp" }];
      this.form.show = false;
      this.form.titleEN = "";
      this.form.titleKA = "";
      this.form.descriptionKA = "";
      this.form.descriptionEN = "";
      this.form.video = null;
    },
  },
};
</script>

<style scoped>
label {
  font-family: mtavruliBOLD !important;
}
.input {
  border-radius: 0px;

  width: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  padding-left: 10px;
  height: 40px;
}
.checkbox {
  margin-bottom: 20px;
  margin-left: 10px;
}
input[type="checkbox"] {
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}
label {
  margin-top: 20px;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.team {
  margin-bottom: 10px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
i {
  cursor: pointer;
  margin-left: 20px;
  font-size: 20px;
}
.add_component {
  margin-bottom: 20px;
}
</style>
